var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v("all items")])]),_c('v-data-table',{attrs:{"headers":[
        {
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false
        },
        { text: 'wp post id', value: 'post_id', filterable: false },
        {
          text: 'coordinates',
          value: 'hasCoordinates',
          filterable: false
        },
        {
          text: 'Content Text',
          value: 'hasContentText',
          filterable: false
        },
        {
          text: 'Marker Number',
          value: 'Site_Marker_Number',
          filterable: true
        },
        { text: 'Point Name', value: 'Point_Name', filterable: true },

        {
          text: 'type',
          value: 'section_type',
          filterable: true
        },
        {
          text: 'layer',
          value: 'section_slug',
          filterable: true
        },
        {
          text: 'icon_obj',
          value: 'icon_obj',
          filterable: false,
          sortable: false
        },

        {
          text: 'icon title',
          value: 'icon_obj.title',
          filterable: false
        },
        {
          text: 'media count',
          value: 'attachedMediaCount',
          filterable: false
        },
        {
          text: 'Media',
          value: 'media',
          filterable: false
        },

        {
          text: 'meta data count',
          value: 'structuredDataCount',
          filterable: false
        },

        {
          text: 'details',
          value: 'details',
          filterable: false,
          sortable: false
        }
      ],"items":_vm.markerListData,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': [_vm.itemsPerPage ].concat( _vm.itemPerPageOption)
      },"search":JSON.stringify({
          markerSearch: _vm.markerSearch,
          selectedSectionTypes: _vm.selectedSectionTypes,
          selectabledLayers: _vm.selectabledLayers
        }),"custom-filter":_vm.filterFunction},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ItemDialog',{attrs:{"data":item}})]}},{key:"item.details",fn:function(ref){
        var item = ref.item;
return [_c('ItemDialog',{attrs:{"title":item.structuredDataCount,"data":item}})]}},{key:"top",fn:function(ref){
        var pagination = ref.pagination;
        var options = ref.options;
        var updateOptions = ref.updateOptions;
return [_c('v-form',[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"mt-4 ml-6"},[_vm._v(" type: ")]),_c('v-select',{staticClass:"mx-4 mr-4",attrs:{"items":_vm.sectionTypes,"label":"Section Types","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedSectionTypes.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedSectionTypes),callback:function ($$v) {_vm.selectedSectionTypes=$$v},expression:"selectedSectionTypes"}}),_c('div',{staticClass:"mt-4 ml-6"},[_vm._v(" layer: ")]),_c('v-select',{staticClass:"mx-4 mr-4",attrs:{"items":_vm.selectableLayers,"label":"Section Types","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectabledLayers.length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectabledLayers),callback:function ($$v) {_vm.selectabledLayers=$$v},expression:"selectabledLayers"}}),_c('v-text-field',{staticClass:"mx-4 mr-4",attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":""},model:{value:(_vm.markerSearch),callback:function ($$v) {_vm.markerSearch=$$v},expression:"markerSearch"}}),_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[_vm.itemsPerPage ].concat( _vm.itemPerPageOption),"items-per-page-text":"Rows per page"},on:{"update:options":updateOptions}})],1)])]}},{key:"item.post_id",fn:function(ref){
        var item = ref.item;
return [_c('div',[_c('a',{attrs:{"href":("" + (_vm.appSettings.wpEditSite) + (item.post_id) + "&action=edit"),"target":"_blank"}},[_vm._v(" edit: "+_vm._s(item.post_id)+" ")])])]}},{key:"item.icon_obj",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"OmStatus"},[_c('v-avatar',{staticClass:"profile",attrs:{"size":_vm.iconSize,"tile":""}},[_c('v-img',{attrs:{"src":item.icon_obj.marker}})],1),_vm._v(" "+_vm._s(item.icon_obj.scale_factor ? ("s: " + (item.icon_obj.scale_factor)) : "")+" ")],1)]}},{key:"item.media",fn:function(ref){
        var item = ref.item;
return [_c('div',_vm._l((item.media),function(mediaItem,index){return _c('a',{key:((item.post_id) + "_media_" + index),attrs:{"href":mediaItem.src_gallery,"target":"_blank"}},[_c('v-avatar',{staticClass:"profile",attrs:{"size":_vm.iconSize,"tile":""}},[_c('v-img',{attrs:{"title":("" + (JSON.stringify({
                    title: mediaItem.title,
                    slug: mediaItem.slug,
                    caption: mediaItem.caption
                  }))),"src":mediaItem.src_thumb}})],1)],1)}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }