var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Point_Description)?_c('div',{staticClass:" secondary--text mt-2"},[_c('div',{staticClass:"dummyMeasureElement",staticStyle:{"visibility":"hidden","height":"0","overflow":"hidden"}},[_c('v-card-text',{ref:"pointDescriptionParentElement",staticClass:"pb-1 pt-0 Point_Description showLess"},[_c('div',{ref:"pointDescriptionHtmlElement",domProps:{"innerHTML":_vm._s(_vm.Point_Description)}})])],1),_c('v-card-text',{class:[
      '',
      'pb-1',
      'pt-0',
      'Point_Description',
      _vm.showMore || !_vm.Point_Description_CanCrop ? 'showMore' : 'showLess'
    ],attrs:{"id":("SiteInfo_Point_Description_parent_" + _vm.type)}},[_c('div',{attrs:{"id":("SiteInfo_Point_Description_htmlAct_" + _vm.type)},domProps:{"innerHTML":_vm._s(_vm.Point_Description)}}),(!_vm.showMore && _vm.Point_Description_CanCrop)?_c('div',{staticClass:"fade"}):_vm._e()]),_c('v-card-text',{staticClass:"pt-0 primary--text "},[(_vm.Point_Description_CanCrop && _vm.type == 'siteInfo')?_c('a',{staticClass:"primary--text",staticStyle:{"text-decoration":"underline!important"},on:{"click":function($event){return _vm.functions.updateShowMore(!_vm.showMore)}}},[(_vm.showMore)?_c('font',[_vm._v(" Show Less")]):_vm._e(),(!_vm.showMore)?_c('font',[_vm._v(" Show More")]):_vm._e()],1):_vm._e(),(
        _vm.functions.openSidebarRemote &&
          _vm.type == 'popup' &&
          //$vuetify.breakpoint.mdAndDown &&
          _vm.Point_Description_CanCrop
      )?_c('a',{staticClass:"primary--text",staticStyle:{"text-decoration":"underline!important"},on:{"click":function($event){_vm.functions.openSidebarRemote ? _vm.functions.openSidebarRemote(true) : null}}},[_c('font',[_vm._v(" Show More")])],1):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }