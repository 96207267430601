<template>
  <div v-if="Point_Description" class=" secondary--text mt-2">
    <div
      class="dummyMeasureElement"
      style="visibility: hidden;height:0; overflow:hidden;"
    >
      <v-card-text
        class="pb-1 pt-0 Point_Description showLess"
        ref="pointDescriptionParentElement"
      >
        <div ref="pointDescriptionHtmlElement" v-html="Point_Description"></div>
      </v-card-text>
    </div>
    <v-card-text
      :id="`SiteInfo_Point_Description_parent_${type}`"
      :class="[
        '',
        'pb-1',
        'pt-0',
        'Point_Description',
        showMore || !Point_Description_CanCrop ? 'showMore' : 'showLess'
      ]"
    >
      <div
        :id="`SiteInfo_Point_Description_htmlAct_${type}`"
        v-html="Point_Description"
      ></div>

      <div class="fade" v-if="!showMore && Point_Description_CanCrop"></div>
    </v-card-text>
    <v-card-text class="pt-0 primary--text ">
      <a
        @click="functions.updateShowMore(!showMore)"
        v-if="Point_Description_CanCrop && type == 'siteInfo'"
        class="primary--text"
        style="text-decoration: underline!important;"
      >
        <font v-if="showMore"> Show Less</font>
        <font v-if="!showMore"> Show More</font>
      </a>

      <a
        v-if="
          functions.openSidebarRemote &&
            type == 'popup' &&
            //$vuetify.breakpoint.mdAndDown &&
            Point_Description_CanCrop
        "
        @click="
          functions.openSidebarRemote ? functions.openSidebarRemote(true) : null
        "
        class="primary--text"
        style="text-decoration: underline!important;"
      >
        <font> Show More</font></a
      >
    </v-card-text>
  </div>
</template>

<script>
import { eventBus } from "../../main";
export default {
  name: "PointDescriptionBody",
  components: {},
  computed: {
    Point_Description_CanCrop() {
      return !this.justShowAllText; //this.Point_Description.length > this.descriptionCropLength;
    }
  },
  data: () => ({
    justShowAllText: false
  }),
  props: {
    functions: Object,
    type: String,
    Point_Description: String,
    showMore: Boolean
  },
  methods: {
    getPointDescriptionHeightAndDetails() {
      let height = false;
      let parentHeight = false;

      try {
        if (this.Point_Description) {
          const htmlBlockDiv = this.$refs.pointDescriptionHtmlElement;
          if (htmlBlockDiv) {
            height = htmlBlockDiv.offsetHeight;
          }
        }
        try {
          const parentDiv = this.$refs.pointDescriptionParentElement;
          if (parentDiv) {
            parentHeight = parentDiv.offsetHeight;
          }
        } catch (error) {
          //do nothing;
        }
      } catch (error) {
        //do nothing;
      }

      let justShowAll = false;
      if (height && parentHeight && height < parentHeight + 100) {
        justShowAll = true;
        this.justShowAllText = true;
      } else {
        this.justShowAllText = false;
      }

      if (this.Point_Description && !height) {
        setTimeout(() => {
          this.getPointDescriptionHeightAndDetails();
        }, 200);
      }

      return { height, parentHeight, justShowAll };
    }
  },
  async mounted() {
    this.getPointDescriptionHeightAndDetails();

    eventBus.$on("pointDiscription_expand", () => {
      if (this.type == "siteInfo") {
        //this.showMore = true;
        this.functions.updateShowMore(true);
      }
    });
  },

  watch: {
    Point_Description: function(newValue) {
      if (newValue) {
        //do nothingk!!!
      }
      this.justShowAllText = false;

      this.getPointDescriptionHeightAndDetails();
    }
  }
};
</script>
<style></style>
