<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="640" class="video">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on"> </span>
      </template>

      <v-card class="youTubeDialog">
        <v-card-title
          v-if="false"
          :style="{
            background: appSettings.menuBarColor
          }"
          :class="
            appSettings.headingTextColor + ' headline  hidden-sm-and-down'
          "
        >
          {{ youTubeTitle }}
        </v-card-title>
        <div ref="box" v-bind:style="{ xmaxHeight: maxHeight + 'px' }">
          <youtube
            v-if="youTubeAddress"
            player-width="100%"
            :video-id="getUrl(youTubeAddress)"
            :player-vars="{ autoplay: 1 }"
          ></youtube>
        </div>

        <v-card-actions class="my-0 py-0">
          <v-spacer></v-spacer>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getIdFromURL } from "vue-youtube-embed";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      maxHeight: 600
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      youTubeTitle: state => state.media.youTubeTitle,
      youTubeOpen: state => state.media.youTubeOpen,
      youTubeAddress: state => state.media.youTubeAddress
    })
  },
  methods: {
    getUrl(url) {
      let videoId = getIdFromURL(url);
      return videoId;
    },
    getSize() {
      setTimeout(() => {
        const box = this.$refs.box;
        const boxSize = box.getBoundingClientRect();

        this.maxHeight = (boxSize.width * 6) / 10;
      }, 200);
    }
  },

  mounted() {},

  watch: {
    youTubeOpen: function(newValue) {
      this.$store.dispatch("mediaClearYouTubeTracker");
      if (newValue === true && this.youTubeAddress) {
        this.$store.dispatch("mediaClearYouTubeTracker");

        this.dialog = true;
        this.getSize();
      }
    },

    dialog: function(newValue) {
      if (newValue === false) {
        this.$store.dispatch("mediaClearYouTubeAddress");
      }
    }
  }
};
</script>
<style>
.youTubeDialog {
  max-height: 100vh;
  max-width: 100vw;
}

@media only screen and (max-height: 500px) {
  .youTubeDialog iframe,
  .streetViewDialog iframe {
    max-height: calc(100vh - 150px);
  }
}
</style>
