<template>
  <div>
    <v-card>
      <v-card-title>
        <h1>all items</h1>
      </v-card-title>
      <v-data-table
        :headers="[
          {
            text: 'Actions',
            value: 'actions',
            filterable: false,
            sortable: false
          },
          { text: 'wp post id', value: 'post_id', filterable: false },
          {
            text: 'coordinates',
            value: 'hasCoordinates',
            filterable: false
          },
          {
            text: 'Content Text',
            value: 'hasContentText',
            filterable: false
          },
          {
            text: 'Marker Number',
            value: 'Site_Marker_Number',
            filterable: true
          },
          { text: 'Point Name', value: 'Point_Name', filterable: true },

          {
            text: 'type',
            value: 'section_type',
            filterable: true
          },
          {
            text: 'layer',
            value: 'section_slug',
            filterable: true
          },
          {
            text: 'icon_obj',
            value: 'icon_obj',
            filterable: false,
            sortable: false
          },

          {
            text: 'icon title',
            value: 'icon_obj.title',
            filterable: false
          },
          {
            text: 'media count',
            value: 'attachedMediaCount',
            filterable: false
          },
          {
            text: 'Media',
            value: 'media',
            filterable: false
          },

          {
            text: 'meta data count',
            value: 'structuredDataCount',
            filterable: false
          },

          {
            text: 'details',
            value: 'details',
            filterable: false,
            sortable: false
          }
        ]"
        :items="markerListData"
        :items-per-page="itemsPerPage"
        :footer-props="{
          'items-per-page-options': [itemsPerPage, ...itemPerPageOption]
        }"
        :search="
          JSON.stringify({
            markerSearch,
            selectedSectionTypes,
            selectabledLayers
          })
        "
        :custom-filter="filterFunction"
      >
        <template v-slot:item.actions="{ item }">
          <ItemDialog :data="item" />
        </template>

        <template v-slot:item.details="{ item }">
          <ItemDialog :title="item.structuredDataCount" :data="item" />
        </template>

        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-form>
            <div class="d-flex flex-row">
              <div class="mt-4 ml-6">
                type:
              </div>
              <v-select
                v-model="selectedSectionTypes"
                :items="sectionTypes"
                label="Section Types"
                multiple
                solo
                class="mx-4 mr-4"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedSectionTypes.length - 1 }} others)
                  </span>
                </template>
              </v-select>

              <div class="mt-4 ml-6">
                layer:
              </div>
              <v-select
                v-model="selectabledLayers"
                :items="selectableLayers"
                label="Section Types"
                multiple
                solo
                class="mx-4 mr-4"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectabledLayers.length - 1 }} others)
                  </span>
                </template>
              </v-select>

              <v-text-field
                v-model="markerSearch"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                class="mx-4 mr-4"
              ></v-text-field>

              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :items-per-page-options="[itemsPerPage, ...itemPerPageOption]"
                items-per-page-text="Rows per page"
              />
            </div>
          </v-form>
        </template>

        <template v-slot:item.post_id="{ item }">
          <div>
            <a
              :href="`${appSettings.wpEditSite}${item.post_id}&action=edit`"
              target="_blank"
            >
              edit: {{ item.post_id }}
            </a>
          </div>
        </template>

        <template v-slot:item.icon_obj="{ item }">
          <div class="OmStatus">
            <v-avatar class="profile" :size="iconSize" tile>
              <v-img :src="item.icon_obj.marker"></v-img>
            </v-avatar>
            {{
              item.icon_obj.scale_factor
                ? `s: ${item.icon_obj.scale_factor}`
                : ""
            }}
          </div>
        </template>

        <template v-slot:item.media="{ item }">
          <div>
            <a
              v-for="(mediaItem, index) in item.media"
              :key="`${item.post_id}_media_${index}`"
              :href="mediaItem.src_gallery"
              target="_blank"
            >
              <v-avatar class="profile" :size="iconSize" tile>
                <v-img
                  :title="
                    `${JSON.stringify({
                      title: mediaItem.title,
                      slug: mediaItem.slug,
                      caption: mediaItem.caption
                    })}`
                  "
                  :src="mediaItem.src_thumb"
                ></v-img>
              </v-avatar>
            </a>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import ItemDialog from "./ItemDialog.vue";

export default {
  name: "allMapItems_TableDataView",
  components: { ItemDialog },

  props: {
    itemsPerPage: Number,
    itemPerPageOption: Array
  },

  data() {
    return {
      markerSearch: "",
      iconSize: 40,
      selectedSectionTypes: [],
      selectabledLayers: []
    };
  },
  methods: {
    filterFunction(value, search, item) {
      search = JSON.parse(search);

      //selectedSectionTypes
      //selectabledLayers

      let layer =
        !search.selectabledLayers.length ||
        search.selectabledLayers.includes(item.section_slug);

      let mainFilter =
        value != null &&
        search.markerSearch != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.markerSearch.toLocaleUpperCase()) !== -1;

      return mainFilter && layer;
    }
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      markerSections: state => state.markers.sections,

      markerList: state => state.markers.markerList,
      geoJson_signage: state => state.markers.geoJson_signage,
      geoJson_artwork: state => state.markers.geoJson_artwork
    }),
    sectionTypes() {
      let section_types = [];
      for (let section in this.markerSections) {
        section_types.push(this.markerSections[section].section_type);
      }
      return [...new Set(section_types)];
    },
    selectableLayers() {
      let layers = [];
      if (this.selectedSectionTypes?.length) {
        for (let section in this.markerSections) {
          if (
            this.selectedSectionTypes.includes(
              this.markerSections[section].section_type
            )
          ) {
            layers.push(section);
          }
        }
      }
      return layers;
    },

    markerListData() {
      let dataList = [
        ...this.markerList.data.features,
        ...this.geoJson_signage.data.features,
        ...this.geoJson_artwork.data.features
      ];

      return dataList.map(row => {
        return {
          ...row.properties,
          attachedMediaCount: row?.properties?.media?.length
            ? row?.properties?.media?.length
            : 0,

          structuredDataCount: row?.properties?.structuredData
            ? _.size(row?.properties?.structuredData)
            : 0,

          coordinates: row?.geometry?.coordinates,
          hasContentText: row?.properties?.content?.rendered ? "yes" : "no",
          hasCoordinates:
            !isNaN(row?.properties?.Longitude) &&
            !isNaN(row?.properties?.Latitude)
              ? "yes"
              : "no"
        };
      });
    }
  },

  watch: {
    /*sectionTypes: function(value) {
      this.selectedSectionTypes = [...value];
    },*/
    selectableLayers: function(value) {
      this.selectabledLayers = [...value];
    }
  }
};
</script>

<style></style>
