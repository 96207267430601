<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="
        $vuetify.breakpoint.xsOnly
          ? '95%'
          : $vuetify.breakpoint.smAndDown
          ? 550
          : 650
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class=" " @click="scrollToSiteDelay()">
          FAQ
        </span>
      </template>

      <v-card>
        <v-card-title
          v-if="true"
          :style="{
            color: 'black',
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          FAQs
        </v-card-title>

        <v-row no-gutters class="secondary--text " style="">
          <v-col
            cols="12"
            md="12"
            class="pt-3 px-4 imageWrapper"
            id="HelpActWrapper"
            @scroll="onScroll"
          >
            <center id="topOfHelpSection">
              <v-card-title id="helpHeading_nav" class="justify-center"
                >How do I navigate around the map on my phone?
              </v-card-title>

              <v-card-text>
                Use two fingers to pinch and zoom in and out. <br />
                Use one finger to navigate around the map.
              </v-card-text>
              <br />
              <br />
              <v-divider></v-divider>
              <v-card-title id="helpHeading_gps" class="justify-center"
                >How do I find my location in the zoo?
              </v-card-title>
              <v-card-text>
                Tap the location button on the left-hand side. If prompted, you
                may need to allow location services on your phone. This
                information is <u>not</u> collected nor retained by Zoos
                Victoria or Maptivate.
              </v-card-text>

              <img src="/assets/help/nav.png" />

              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_search" class="justify-center"
                >How do I search for an animal or place?
              </v-card-title>
              <v-card-text
                >Open the menu by clicking the three white lines in the top
                corner of the map then find the yellow ‘List Filter’ search bar
                and type in what you’re looking for:
              </v-card-text>
              <img src="/assets/help/filter.png" />
              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_sensory" class="justify-center"
                >How can I view the sensory awareness filter?
              </v-card-title>
              <v-card-text
                >Open the Layer Filters menu by clicking the layer icon in the
                top corner of the map (green with a black square and line inside
                it). Note: description of each sensory icon is displayed in the
                Map Legend in the bottom right corner of the screen.
              </v-card-text>
              <img src="/assets/help/layers.png" />
              <v-card-text>Select the Sensory Awareness layer:</v-card-text>
              <img src="/assets/help/sensory.png" />

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_keeper_talks" class="justify-center"
                >How can I see a list of Keeper Talk times?
              </v-card-title>
              <v-card-text
                >Open the menu by clicking the three white lines in the top
                corner of the map and select Keeper Talks to see a list of
                clickable talk times and locations:
              </v-card-text>
              <img src="/assets/help/keepertalks.png" />

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_language" class="justify-center"
                >How do I translate the map into another language?
              </v-card-title>
              <v-card-text
                >Click on the translation button in the top left of the screen
                to see a list of languages to choose from.
              </v-card-text>
              <img src="/assets/help/translate.png" />

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_legend" class="justify-center"
                >What do the symbols on the map mean and how can I view the map
                legend?
              </v-card-title>
              <v-card-text
                >Click on the map symbol in the bottom right of the screen to
                bring up the legend.
              </v-card-text>
              <img src="/assets/help/legend.png" />

              <br />
              <br />
              <br />
              <br />

              <v-divider></v-divider>
              <v-card-title id="helpHeading_pdf" class="justify-center"
                >Where can I find a PDF map?
              </v-card-title>
              <v-card-text
                >You can download a PDF map here:

                <maptivateInfoButton
                  :width="'200px'"
                  :color="appSettings.mediaButtonColor"
                  icon="mdi-file-document-outline"
                  title="Download PDF MAP"
                  target="_blank"
                  :href="
                    'https://cms-prod2019.zoo.org.au/media/8101/40295_zv-summer-map_mz_v3-web.pdf'
                  "
                />
              </v-card-text>

              <br />
              <br />
            </center>
          </v-col>
        </v-row>

        <v-card-actions>
          <transition name="fade" mode="out-in">
            <v-btn
              v-show="!atTop"
              class="v-fade"
              depressed
              color="primary"
              @click="scrollToSite(false)"
            >
              Top
            </v-btn>
          </transition>

          <v-spacer></v-spacer>

          <v-btn depressed color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";
import maptivateInfoButton from "../ui/maptivateInfoButton";

export default {
  name: "HelpModal",
  components: {
    maptivateInfoButton
  },
  data() {
    return {
      atTop: true,
      atBottom: false,
      dontShowAgain: false,
      dialog: false,
      title: "Help"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadHelpDialog", scrollTo => {
      this.dialog = true;

      setTimeout(() => {
        this.scrollToSite(scrollTo);
      }, 500);
    });
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let atBottom = false;
      let atTop = false;
      if (scrollTop + clientHeight >= scrollHeight) {
        atBottom = true;
      }
      if (scrollTop < 20) {
        atTop = true;
      }

      this.atTop = atTop;
      this.atBottom = atBottom;
    },
    scrollToSiteDelay() {
      setTimeout(() => {
        this.scrollToSite();
      }, 100);
    },
    scrollToSite(newValue) {
      let element = `topOfHelpSection`;

      if (newValue) {
        element = `helpHeading_${newValue}`;
      }

      var AboutContentWrapper = document.getElementById("HelpActWrapper");
      var heading = document.getElementById(element);

      // Scroll the 'myDiv' to bring the 'heading' element into view
      AboutContentWrapper.scrollTop =
        heading.offsetTop - AboutContentWrapper.offsetTop;
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}
</style>
