const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        //  "pk.eyJ1IjoiYWFyb25zaW1zIiwiYSI6ImNsbndnb2NvNDA3bmMycXFtZW1wZWhscHYifQ.EG2ERCbsf9xhfEPX1_ITpg",//Aaros key:
        "pk.eyJ1IjoiaW5mby1tYXB0aXZhdGUiLCJhIjoiY2xmbTF1Ym9wMDdmczQxbW9vcmdmYm92cCJ9.UgrbRYg9Chk1JJzNvgnQNw", //maptivate key;
      options: {
        //style: "mapbox://styles/info-maptivate/clnihrsaj01md01r756jrduah",
        style: "mapbox://styles/info-maptivate/clqbqhfqp003t01rc54rtcitk",
        //style: "mapbox://styles/info-maptivate/clocbzi7k00bh01rc6s3bclm8", //main", //main v3x
        //style: "mapbox://styles/info-maptivate/clqbl0cng003t01rjh6zydrgg", //v2.3
        //style: "mapbox://styles/mapbox/outdoors-v12", //main", //main
        //style: "mapbox://styles/aaronsims/clnwh2rhk001f01r7c7tk9i3p",//testing
        center: [144.9515, -37.7841],
        maxZoom: 24,
        minZoom: 13,
        pitch: 40,
        bearing: -45,
        //bearing: 0,
        zoom: 15.8
      },
      bounds: [
        [144.85036143131515, -37.8533280298009], // Southwest coordinates
        [145.0615299795112, -37.712797163701886] // Northeast coordinates
      ],
      defaultSetup: {
        center: [144.9515, -37.7841],
        zoom: 20,
        zoomTab: 16,
        zoomMob: 16,
        pitch: 30,
        bearing: -45
        //bearing: 0
      },
      flyToBounds: [
        [144.94990601491298, -37.78597242494533],
        [144.95311396439007, -37.78286633040725]
      ],
      disableRotationAndBearing: false
    },

    wpEditSite:
      "https://cms02.maptivateapp.com.au/melbourne-zoo/wp-admin/post.php?post=",

    onMoreInfoLoadOnMobileExpanText: true,

    pointsHaveDirections: false,
    markerIsPointer: false,
    showSiteNumbers: false,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layersUnder: {
      art: null,//"melbzoo-labels (1)",
      places: null,//"melbzoo-labels (1)",
      signage: null,//"melbzoo-labels (1)",
      lines: false,
      fill: false,
      arrows: "top-layer-1xklb5 (1)"
    },
    defaultTooltipLabelField: "mv_tooltip",

    markerImageAssetOrderPriority: {
      //list by section slug name,
      artwork_section: {
        catTaxonomy: "artwork_section",
        name: "artwork",
        behaviour: "normal", //normal, cluster,
        skip: [],
        priority: [
          "artwork_zoom1",
          "animals-hero",
          "buildings-hero",
          "north-arrow"
        ],
        delay: []
      },
      signage_section: {
        catTaxonomy: "signage_section",
        name: "signage",
        behaviour: "normal", //normal, cluster,
        skip: [""],
        priority: [""],
        delay: ["sensory"]
      },
      markersection: {
        catTaxonomy: "markersection",
        name: "marker",
        behaviour: "cluster",
        load: ["fighting_extinction_species"]
      }
    },
    layerDefaults: {
      signage: {
        labelField: "mv_label",

        scaling: {
          minZoom: 16.7,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 0.7
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 1, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        labelField: "mv_label",

        useCircle: true,
        scaling: {
          minZoom: 17.5,
          opacityMinZoom: 5,
          opacityMaxZoom: 6,

          clusterOpacityMinZoom: 5,
          clusterOpacityMaxZoom: 6,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: false,
        styles: {
          //unclustered_paint: { "circle-color": "#ff0000" },
          //clustered_paint: { "circle-color": "#354d64" },

          unclustered_icon_size: {
            stops: [
              [16, 7.5 / 35],
              [17, 15 / 35]
            ]
          },
          unclustered_radius: {
            stops: [
              [16, 7.5],
              [17, 15]
            ]
          },
          clustered_radius: {
            stops: [
              [16, 10],
              [17, 20]
            ]
          }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterLabel: true,
        clusterSetup: {
          cluster: true,
          clusterMaxZoom: 18,
          clusterRadius: 30,

          clusterProperties: {}
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          unclustered_layer_symbol: [
            "all",
            ["!has", "point_count"],
            ["==", "render_icons", true]
          ],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    /*categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        title: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        title: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        title: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },
*/
    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    siteListSettings: {
      itemUseCircle: true,
      sectionUseCircle: true
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    zoomToOnSelect: 18,

    allowSkipWelcomeDialog: false,

    autoOpenMoreInfo: true,
    drawWidth: 450,
    mobDrawWidth: 300,

    popupButtonWidth: "269px",
    drawButtonWidth: "435px",

    mediaButtonColor: "#879c33", //a6caa6
    filterLegendButtonColor: "#51702d", //a6caa6

    appButtonColor: "black",
    dialogActionButtonColor: "black",
    logoTextColor: "#e9e3cd",
    filterButtonColor: "#fae08c",
    mainMenuBarColor: "#51702d",
    menuBarColor: "#879c33",
    audioPlayColor: "white",
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerTextLabel: "black",
      markerText: "white",
      markerTextBg: "white"
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
